html {
  overflow: hidden;
  --stroke-width: 1px;
  background-color: var(--back-color);
  color: var(--main-color);
  user-select: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--back-color);
  color: var(--main-color);
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb:vertical {
  background-color: transparent;
  border-radius: 0px;
  border: var(--stroke-width) solid var(--main-color);
  /* border-right: none; */
}

*::-webkit-scrollbar-thumb:horizontal {
  background-color: transparent;
  border-radius: 0px;
  border: var(--stroke-width) solid var(--main-color);
  border-bottom: none;
}

*::selection {
  background-color: var(--high-color);
  color: var(--main-color);
}
