@media (prefers-color-scheme: light) {
  html,
  body {
    --back-color: #e9eaeb;
    --main-color: #3b3b3b;
    --high-color: #b9373e;
  }

  label.theme[for='light'] {
    border: var(--stroke-width) solid var(--high-color);
  }
  input[name='theme']:not(#light):checked ~ label[for='light'] {
    border: none;
  }
}

@media (prefers-color-scheme: dark) {
  html:not(.light, .blue, .green),
  body {
    --back-color: #1c1e1f;
    --main-color: #d3d3d3;
    --high-color: #b0f566;
  }

  label.theme[for='dark'] {
    border: var(--stroke-width) solid var(--high-color);
  }
  input[name='theme']:not(#dark):checked ~ label[for='dark'] {
    border: none;
  }
}
body.light {
  --back-color: #e9eaeb !important;
  --main-color: #3b3b3b !important;
  --high-color: #b9373e !important;
}
body.dark {
  --back-color: #1c1e1f;
  --main-color: #d3d3d3;
  --high-color: #b0f566;
}
body.green {
  --back-color: #01633b;
  --main-color: #ffffff;
  --high-color: #f78ae0;
}
body.blue {
  --back-color: #1f2da4;
  --main-color: #ffffff;
  --high-color: #b0f566;
}

html:has(input#light:checked) {
  --back-color: #e9eaeb;
  --main-color: #3b3b3b;
  --high-color: #b9373e;
}
html:has(input#dark:checked) {
  --back-color: #1c1e1f;
  --main-color: #d3d3d3;
  --high-color: #b0f566;
}
html:has(input#green:checked) {
  --back-color: #01633b;
  --main-color: #ffffff;
  --high-color: #f78ae0;
}
html:has(input#blue:checked) {
  --back-color: #1f2da4;
  --main-color: #ffffff;
  --high-color: #b0f566;
}

.themes {
  display: flex;
  /* position: absolute; */
  /* right: 1vmin; */
  /* top: 0; */
  position: sticky;
  top: 0;
}

input[name='theme'] {
  position: absolute;
  left: -9999vw;
}

label.theme {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

label.theme[for='light'],
label.fake-light {
  background-color: #e9eaeb;
}
label.theme[for='dark'],
label.fake-dark {
  background-color: #1c1e1f;
}
label.theme[for='green'] {
  background-color: #01633b;
}
label.theme[for='blue'] {
  background-color: #1f2da4;
}

input#light:checked ~ label[for='light'],
input#dark:checked ~ label[for='dark'],
input#green:checked ~ label[for='green'],
input#blue:checked ~ label[for='blue'] {
  border: var(--stroke-width) solid var(--high-color);
}
