.logo-container {
  --logo-width: 300px;
  width: var(--logo-width);
  height: calc(var(--logo-width) / 2);
  font-size: calc(var(--logo-width) / 40);
  position: relative;
  overflow: visible;
}

.line {
  margin: 3vmin 0;
  width: 20vmin;
  border-bottom: var(--stroke-width) solid #eee;
}

.slogan {
  margin: 3vmin 0;
  font-size: 20px;
  font-family: monospace;
  font-weight: 100;
}
