.logo {
  width: 200%;
  height: 100%;
  display: flex;
  background-color: transparent;
  cursor: default;
  overflow: visible;
  --gap: 0.5em;
  --stroke: 0.1em;
}
.left,
.right {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.left {
  width: 50%;
  display: flex;
  flex-direction: column;
  z-index: 999;
  overflow: hidden;
}
.right {
  width: 100%;
  white-space: nowrap;
  overflow: visible;
  clip-path: polygon(50% 0%, 400% 0%, 400% 200%, 50% 200%);
  transition: all 1s;
}

.text {
  display: flex;
}
.text span {
  transition: all ease 1s 0.5s;
}
.text .normal {
  max-width: 0;
  opacity: 0;
}

.right .text {
  -webkit-text-stroke: var(--stroke-width) var(--main-color);
  color: transparent;
}

.text {
  -webkit-text-stroke: var(--stroke-width) var(--main-color);
  position: absolute;
  font-size: 12em;
  bottom: 0;
  left: 0;
  color: transparent;
  font-weight: bolder;
  font-family: 'Hind', sans-serif;
  vertical-align: bottom;
  line-height: 0.8;
  user-select: text;
  transition: all 0.5s;
  letter-spacing: 1px;
}

.frame {
  /* background-color: var(--main-color); */
  border: var(--stroke-width) solid var(--main-color);
  transition: all ease 0.5s;
}

.frame:not(:last-child) {
  margin-bottom: var(--gap);
}
.height15 {
  flex: 15;
}
.height14 {
  flex: 14;
}
.height13 {
  flex: 13;
}
.height12 {
  flex: 12;
}
.height11 {
  flex: 11;
}
.height10 {
  flex: 10;
}
.height9 {
  flex: 9;
}
.height8 {
  flex: 8;
}
.height7 {
  flex: 7;
}
.height6 {
  flex: 6;
}
.height5 {
  flex: 5;
}
.height4 {
  flex: 4;
}
.height3 {
  flex: 3;
}
.height2 {
  flex: 2;
}
.height1 {
  flex-grow: 1;
  border-color: var(--high-color);
}

/* .logo.expand .frame { */
/* background-color: transparent; */
/* } */

.logo.expand .right .text .normal {
  max-width: 1000px;
  opacity: 1;
}
.logo.expand .right .text span.strong {
  -webkit-text-stroke: var(--stroke-width) var(--high-color);
  /* color: var(--main-color); */
}

.logo.expand .left .text {
  color: var(--main-color);
}
.logo.expand .frame:not(:first-child) {
  margin-bottom: var(--gap);
}

.logo.expand .height1 {
  flex-grow: 15;
  /* background-color: var(--high-color); */
}
.logo.expand .height2 {
  flex: 14;
}
.logo.expand .height3 {
  flex: 13;
}
.logo.expand .height4 {
  flex: 12;
}
.logo.expand .height5 {
  flex: 11;
}
.logo.expand .height6 {
  flex: 10;
}
.logo.expand .height7 {
  flex: 9;
}
.logo.expand .height8 {
  flex: 8;
}
.logo.expand .height9 {
  flex: 7;
}
.logo.expand .height10 {
  flex: 6;
}
.logo.expand .height11 {
  flex: 5;
}
.logo.expand .height12 {
  flex: 4;
}
.logo.expand .height13 {
  flex: 3;
}
.logo.expand .height14 {
  flex: 2;
}
.logo.expand .height15 {
  flex: 1;
}
