@media only screen and (max-device-width: 600px) {
  .style-container {
    display: none !important;
  }
  .style-mobile {
    display: block !important;
  }
}

@media only screen and (min-device-width: 600px) {
  .style-mobile {
    display: none;
  }
  .style-container {
    display: flex;
    width: 100%;
    min-height: 300px;
    height: 100vh;
  }

  .style-editor {
    display: flex;
    flex-direction: column;
    width: 600px;
  }

  .style-text {
    box-sizing: border-box;
    flex: 1;
    flex-basis: 250px;
    border: var(--stroke-width) solid var(--main-color);
    display: flex;
  }

  .style-component {
    box-sizing: border-box;
    border: var(--stroke-width) solid var(--main-color);
    border-top: none;
    flex: 1;
    flex-basis: 250px;
    display: flex;
  }

  .style-article {
    border: var(--stroke-width) solid var(--main-color);
    border-left: none;
    overflow: hidden;
    flex: 1;
  }

  .style-text .code {
    display: block;
    padding: 10px 0 0 10px;
    outline: none;
    font-family: 'Fira Mono', 'Source Code Pro', monospace, SFMono-Regular,
      Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 18px;
    color: var(--main-color);
    background: none;
    white-space: pre-wrap;
    word-break: break-all;
    line-height: 1.65;
    tab-size: 2;
    overflow-x: hidden;
    overflow-y: auto;
    user-select: auto;
    caret-color: var(--high-color);
    height: calc(50vh - 40px);
  }

  .style-text .code.readonly {
    position: relative;
    cursor: not-allowed;
  }
  /* .style-text .code.readonly::after { */
  /* content: '  This part is readonly'; */
  /* position: absolute; */
  /* width: 100%; */
  /* left: 0; */
  /* bottom: 0; */
  /* border-top: var(--stroke-width) solid var(--main-color); */
  /* } */

  /*
 CSS for the main interaction
*/
  .tabset input[type='radio'] {
    position: absolute;
    left: -200vw;
    width: 0;
    height: 0;
  }
  .tabset .tab-panel {
    display: none;
  }

  .tabset:has(input#HTML:checked) .tab-panels > .tab-panel:first-child,
  .tabset:has(input#CSS:checked) .tab-panels > .tab-panel:nth-child(2),
  .tabset:has(input#RESULT:checked) .tab-panels > .tab-panel:nth-child(1),
  .tabset:has(input#ARTICLE:checked) .tab-panels > .tab-panel:nth-child(1),
  .tabset:has(input#SETTINGS:checked) .tab-panels > .tab-panel:nth-child(2) {
    display: block;
  }

  .tabset label.tab {
    min-width: 90px;
    height: 40px;
    text-align: center;
    position: relative;
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    font-weight: normal;
    color: var(--main-color);
    font-size: 14px;
    z-index: 2;
  }

  .tabset label.RESULT,
  .tabset label.ARTICLE {
    border-right: var(--stroke-width) solid var(--main-color);
  }

  .tabset .border-holder {
    display: inline-block;
    border-bottom: 1px solid var(--main-color);
    flex: 1;
    height: 40px;
  }

  .tabset label.HTML {
    border-right: var(--stroke-width) solid var(--main-color);
  }

  .tabset input#HTML:checked ~ label.CSS {
    border-bottom: var(--stroke-width) solid var(--main-color);
  }
  .tabset input#CSS:checked ~ label.HTML {
    border-bottom: var(--stroke-width) solid var(--main-color);
  }
  .tabset input#ARTICLE:checked ~ label.SETTINGS {
    border-bottom: var(--stroke-width) solid var(--main-color);
  }

  .tabset input#CSS:checked ~ label.CSS {
    border-right: var(--stroke-width) solid var(--main-color);
  }
  .tabset input#SETTINGS:checked ~ label.ARTICLE {
    border-bottom: var(--stroke-width) solid var(--main-color);
  }
  .tabset input#SETTINGS:checked ~ label.SETTINGS {
    border-right: var(--stroke-width) solid var(--main-color);
  }

  .tabset {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .tabs {
    display: flex;
    font-family: 'Hind', sans-serif;
    font-weight: bold;
  }

  .tab-panels {
    width: 100%;
    flex: 1;
    display: flex;
  }

  .tab-panel {
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
    height: 100%;
  }
}
