@media only screen and (max-device-width: 600px) {
  .app {
    display: none;
  }
  body::after {
    position: absolute;
    left: 0;
    top: 45%;
    padding: 20px;
    text-align: center;
    white-space: normal;
    content: 'Mobile version is under building. Please visit this site on Computer Browser for now. Thank you.';
  }
}

.app {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
}

.nav,
.main {
  position: relative;
}

.nav {
  padding: 10vmin 5vmin 0;
  /* mix-blend-mode: overlay; */
}
.main {
  padding: 10vmin 5vmin 0;
  max-width: 1200px;
  margin: 0 auto;
  /* mix-blend-mode: overlay; */
}
