.settings-container {
  margin: 0;
  width: 100%;
  height: calc(100vh - 40px);
  overflow-x: hidden;
  overflow-y: auto;
  user-select: auto;
}

.settings-content {
  margin: 50px auto 0;
  max-width: 800px;
  font-size: 24px;
}
