.article-container {
  margin: 0;
  width: 100%;
  height: calc(100vh - 40px);
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Amiri', serif;
  user-select: auto;
}

.article-content {
  margin: 50px auto 0;
  max-width: 800px;
  font-size: 24px;
}

.article-title {
  font-size: 50px;
  max-width: 800px;
  margin: 100px auto 0;
  font-weight: bold;
}

.callout {
  padding: 30px 60px;
  margin: 30px 0;
  color: var(--back-color);
  background-color: var(--main-color);
  font-weight: bold;
  font-size: 24px;
}

article img {
  width: 100%;
}

article code,
article pre {
  color: var(--main-color);
  font-size: 20px;
  font-family: 'Fira Mono', 'Source Code Pro', monospace, SFMono-Regular,
    Consolas, 'Liberation Mono', Menlo, monospace;
}

article pre {
  border: var(--stroke-width) solid var(--main-color);
  padding: 10px 20px;
}

article a {
  color: var(--high-color);
}

article a:hover {
  filter: hue-rotate(45deg);
}
